<base target="_blank">
<app-sidenav></app-sidenav>
<div class="chat-box-new " *ngIf="userAccessStatus">
    <div class="row">
                <div class="conversation border">

                    <!-- Chat block  start -->
                    
                        <ng-container *ngIf="!chatModeSwitch">
                            <ng-container *ngIf="meetingNotesData">
                                <div class="box messages smooth-scroll" id="chat-download-block" #scrollChat>
                                <div class="message">
                                    <div class="content">
                                        <!-- <button class="markdown-clipboard-button content-copy"
                                            (click)="copyToClipboard(meetingNotesData)">
                                            <i class="fa fa-clone" aria-hidden="true"></i>
                                        </button> -->
                                        
                                        <div class="sticky-btn">
                                          <button class="download-btn" *ngIf="meetingNotesData" title="Export chat" (click)="downloadDocx()">
                                            <img src="../../assets/download.svg" /></button>
                                      </div>
                                  <div id="download" style="font-family: Calibri !important;" *ngIf="meetingNotesData">
                                    <h3 style="margin-top: 60px;"> Meeting Note with Customer  {{accountNameSelected}} </h3>
                                    <h4 *ngIf="about !== 0"> About Company </h4>
                                     <div *ngIf="about !== 0"  [innerHTML]="about"></div>
                                      <h5 *ngIf="reference !== 0">References</h5>
                                      <div *ngIf="reference !== 0"  [innerHTML]="reference"></div>
                                      <h4 *ngIf="table4 !== 0">Account Operating Landscape</h4>
                                      <div *ngIf="table4 !== 0" >
                                        <h6><b>Account NPS Score: {{npsScore}}</b></h6>
                                        <h6><b>Client Value Delivered (Current FY)</b></h6>
                                        <table *ngIf="!noRecordsFound4" border="1" class="dataframe table table-bordered">
                                           <tr>
                                            <th *ngFor="let key of keys4">{{key}}</th>
                                             </tr>
                                              <tr *ngFor="let item of table4Data">
                                                <td *ngFor="let key of keys4">{{item[key]}}</td>
                                              </tr>
                                              </table>
                                              <p *ngIf="noRecordsFound4">No Records Found</p>
                                              <div *ngIf="showBtn4">
                                              <button *ngIf="!showAll4" class="btn btn-primary py-1 btn-sm" title="Show More" (click)="showMore4()" >Show More</button>
                                              <button *ngIf="showAll4" class="btn btn-primary py-1 btn-sm" title="Show Less" (click)="showLess4()" >Show Less</button>
                                            </div>
                                            <h6><b>The Total client value delivered is: {{totalClientValue}}</b></h6>
                                            </div>

                                            <h4 *ngIf="ofconTable !== 0">Current Engagement for {{acctechTable}} by Location</h4>
                                            <div *ngIf="ofconTable !== 0"  [innerHTML]="ofconTable"></div>
                                
                                            <h4 *ngIf="techList">Account Technology Landscape</h4>
                                            <!-- <h5>Account Name: {{acctechTable}} </h5> -->
                                            <h5>Targeted Tech Presence for {{acctechTable}} : </h5>
                                            <ul>
                                              <li *ngFor="let tech of techList">{{ tech }}</li>
                                            </ul>
                                        
                                               
                                          <!-- Financial Benchmarking  -->
                                         <h4 *ngIf="finbenTable !== 0"> Financial Benchmarking </h4>
                                         <h5 *ngIf="finbenTable !== 0">Key financial metrics for {{acctechTable}} and it's competitors -</h5>
                                         <h6 *ngIf="finbenTable !== 0" style="color: black;">1.Last 5 years financial summary for {{acctechTable}}</h6>
                                         <div *ngIf="finbenTable !== 0"  [innerHTML]="finbenTable"></div>
                                         <!-- <div *ngIf="financialbench !== 0"  [innerHTML]="financialbench"></div>     -->
                                         
                                          
                                         <!-- Peer Analysis -->
                                         <h6 *ngIf="peertable !== 0" style="color: black;"> 2.Peer Analysis to understand current state of {{acctechTable}} on key financial metrics -</h6>
                                         <div *ngIf="peertable !== 0"  [innerHTML]="peertable"></div> 

                                        <h4 *ngIf="accountNews !== 0"> Account News from XIQ </h4>
                                        <div *ngIf="accountNews !== 0"  [innerHTML]="accountNews"></div>
                                          <h4 *ngIf="account10Q !== 0"> Account Challenges and Priorities per latest 10Q report </h4>
                                            <div *ngIf="account10Q !== 0"  [innerHTML]="account10Q"></div>
                                            <h4 *ngIf="prevConv !== 0">Previous Five Conversations</h4>
                                              <div *ngIf="prevConv !== 0"  [innerHTML]="prevConv"></div>                                              
                                              <h5 *ngIf="convTable !== 0">Conversation Table </h5>
                                                <div *ngIf="convTable !== 0"  [innerHTML]="convTable"></div>                                                 
                                                <h4 *ngIf="dealSummary !== 0">Deal summary (Pipeline, Won, Lost)</h4>
                                                <div *ngIf="dealSummary !== 0"  [innerHTML]="dealSummary"></div> 
                                                <h5 *ngIf="pipeTable !== 0">Pipeline Oppurtunities Table</h5>
                                                <div *ngIf="pipeTable !== 0"  [innerHTML]="pipeTable"></div> 
                                                <h5 *ngIf="wonTable !== 0">Won Opportunities Table</h5>
                                                <div *ngIf="wonTable !== 0"  [innerHTML]="wonTable"></div>
                                                <h5 *ngIf="lostTable !== 0">Lost Opportunities Table</h5>
                                                <div *ngIf="lostTable !== 0"  [innerHTML]="lostTable"></div>
                                                  <h4 *ngIf="table1 !== 0">Competitor’s Contracts due for Renewal </h4>
                                                  <div *ngIf="table1 !== 0" >
                                                    <table *ngIf="!noRecordsFound1" border="1" class="dataframe table table-bordered">
                                                       <tr>
                                                        <th *ngFor="let key of keys1">{{key}}</th>
                                                         </tr>
                                                          <tr *ngFor="let item of table1Data">
                                                            <td *ngFor="let key of keys1">{{item[key]}}</td>
                                                          </tr>
                                                          </table>
                                                          <p *ngIf="noRecordsFound1">No Records Found</p>
                                                          <div *ngIf="showBtn1">
                                                          <button *ngIf="!showAll1" class="btn btn-primary py-1 btn-sm" title="Show More" (click)="showMore1()" >Show More</button>
                                                          <button *ngIf="showAll1" class="btn btn-primary py-1 btn-sm" title="Show Less" (click)="showLess1()" >Show Less</button>
                                                        </div>
                                                        </div>
                                                   <h4 *ngIf="table3 !==0 || table2 !==0">Connections</h4>
                                                   <div *ngIf="table2 !== 0" >
                                                      <h5>Validated connections</h5>
                                                        <table *ngIf="!noRecordsFound2" border="1" class="dataframe table table-bordered">
                                                          <tr>
                                                            <th *ngFor="let key of keys2">{{key}}</th>
                                                            </tr>
                                                          <tr *ngFor="let item of table2Data">
                                                            <td *ngFor="let key of keys2">{{item[key]}}</td>
                                                            </tr>
                                                            </table>
                                                            <p *ngIf="noRecordsFound2">No Records Found</p>
                                                            <div *ngIf="showBtn2">
                                                              <button *ngIf="!showAll2" class="btn btn-primary py-1 btn-sm" title="Show More" (click)="showMore2()" >Show More</button>
                                                              <button *ngIf="showAll2" class="btn btn-primary py-1 btn-sm" title="Show Less" (click)="showLess2()" >Show Less</button>
                                                            </div>
                                                          </div>
                                                     <div *ngIf="table3 !== 0" >
                                                      <h5>Yet to be Validated Connections</h5>
                                                        <table *ngIf="!noRecordsFound3" border="1" class="dataframe table table-bordered">
                                                          <tr>
                                                            <th *ngFor="let key of keys3">{{key}}</th>
                                                             </tr>
                                                              <tr *ngFor="let item of table3Data">
                                                                <td *ngFor="let key of keys3">{{item[key]}}</td>
                                                              </tr>
                                                              </table>
                                                              <p *ngIf="noRecordsFound3">No Records Found</p>
                                                              <div *ngIf="showBtn3">
                                                                <button *ngIf="!showAll3" class="btn btn-primary py-1 btn-sm" title="Show More" (click)="showMore3()" >Show More</button>
                                                                <button *ngIf="showAll3" class="btn btn-primary py-1 btn-sm" title="Show Less" (click)="showLess3()" >Show Less</button>
                                                              </div>
                                                            </div>
                                                          <h4 *ngIf="caseStudies !== 0 || offerings !== 0 ">Genpact’s Right to Play</h4>
                                                          <h5 *ngIf="offerings !== 0">Relevant Offerings</h5>
                                                          <div *ngIf="offerings !== 0"  [innerHTML]="offerings"></div>
                                                          <h5 *ngIf="caseStudies !== 0">Relevant case studies</h5>
                                                          <div *ngIf="caseStudies !== 0"  [innerHTML]="caseStudies"></div>
                                                    </div>
                                                </div>
                                            </div>
                                  </div>
                                    <!-- </div>
                                </div>
                            </div> -->
                            <div class="d-block" style="padding-bottom: 70px;">
                                <div style="display: flex;justify-content: center; padding-left: 250px;">
                                <button class="btn btn-primary py-1 btn-sm" title="Back to Home"
                                (click)="backToHome()" >
                                    Back to Home
                                    </button>
                                    
                                    <div style="padding-left: 100px;" (click)="openModal(like_modal)">
                                        <img src="../../assets/thumpsup.svg">
                                    </div>
                                    <div style="padding-left:15px" (click)="openModal(unlike_modal)">
                                        <img src="../../assets/thumpsdown.png">
                                    </div>
                                </div>    
                            </div>
                            <footer class="footer">
                              <div class="container">
                                <span style="font-size: 10px;">AI-generated response may not be fully accurate, you may copy the response and check with experts for critical information.</span>
                              </div>
                            </footer>
                            </ng-container>
                        </ng-container>
                    

                        <ng-container *ngIf="chatModeSwitch">
                            <div class="text-dark"  style="text-align: center;">
                                <div class="content mt-2" >
                                    <div class="user-info text-dark" style="font-family: Georgia;"> Hello <b class="text-primary" style="color: #0d6efd !important">{{username}}!</b> </div>
                                </div>
                            </div>

                            <div class="text-dark"  style="text-align: center;">
                                <!-- <div class="btn-group" style="float: right; margin-right: 10px; margin-top:-40px" title="There are 8 checkboxes and 3 fields. Each checkbox requires certain fields to be filled. The checkboxes 'offerings' requires 'challenges'. 'account_news', 'challenges', 'about_account', 'competition' and 'oppurtunity' need the 'account' field. 'Conversation' and 'connection' checkboxes require both 'account' and 'buying center' fields.">
                                  <button type="button" class="btn btn-light shadow" (click)="show()">
                                    <i *ngIf="!showCheckboxes" class="fa fa-info" aria-hidden="true"></i>
                                    <i *ngIf="showCheckboxes" class="fa fa-times" aria-hidden="true"></i>

                                  </button>
                                </div> -->
                                <!-- <div *ngIf="!showCheckboxes" class="btn-group" style="float: right; margin-right: 10px; margin-top:-40px" title="There are 8 checkboxes and 3 fields. Each checkbox requires certain fields to be filled. The checkboxes 'offerings' requires 'challenges'. 'account_news', 'challenges', 'about_account', 'competition' and 'oppurtunity' need the 'account' field. 'Conversation' and 'connection' checkboxes require both 'account' and 'buying center' fields.">
                                  <button type="button" class="btn btn-light shadow">
                                    <i class="fa fa-times" aria-hidden="true" (click)="show()"></i>
                                  </button>
                                </div> -->
                            </div>
                            <div class="help"><span>What Can I Assist You With?</span></div>
                            <div class="row justify-content-center mt-2">
                              <div class="col-md-5  d-md-grid mx-2">
                            <div class="row justify-content-center">
                            <div class="col-md-12 card py-2" style="background-image: linear-gradient(to top, #F8F8F8, #F0F0F0);border-radius: 5px;padding: 5px;">
                              <div id="circle">1</div>
                              <div class="row justify-content-center mt-2" >
                                <div class="col-md-11">
                                <!-- <button (click)="toggleDiv()" style="margin-right: 10px; float:left; border: 0">
                                  <span *ngIf="isExpanded"><i class="fa fa-caret-down" aria-hidden="true"></i></span>
                                  <span *ngIf="!isExpanded"><i class="fa fa-caret-right" aria-hidden="true"></i></span>
                                </button> -->
                                <p style=" text-align: center; font-family: Arial; font-size: 14px; margin-bottom : 0.4em">Please select the <b>Meeting Note</b> component(s) or choose "<b>Select All</b>" to include all of them</p>
                              </div>
                            </div>
                              <div class="col-md-10 card" style="background-image: linear-gradient(to bottom, #FFFFFF, #F0F0F0);border-radius: 5px;padding: 5px; align-self: center;margin-bottom: 10px;">
                                <div  class="row justify-content-center">
                                  <label style="text-align: center;"><img src="../../assets/AI icon.png" style="margin-right:10px; font-family: GuardianSansRegular !important"/><b>Meeting Note</b></label> 
                                  <div class="col-md-5  d-flex">
                                    <label class="checkbox-label checkboxx">
                                      <input type="checkbox" id="uploadCheckbox" [(ngModel)]="checkboxes['aboutaccountcheckbox']"  required
                                        [ngModelOptions]="{standalone: true}" style="margin-right: 5px; vertical-align: middle;"
                                        (ngModelChange)="handleCheckboxChange()">
                                        <span style="vertical-align: middle"> 
                                          About the account
                                      </span> 
                                    </label>
                                  </div>
                                  <div class="col-md-6  d-flex">
                                    <label class="checkbox-label checkboxx">
                                        <input type="checkbox" id="uploadCheckbox" [(ngModel)]="checkboxes['offeringscheckbox']" required
                                          [ngModelOptions]="{standalone: true}" style="margin-right: 5px; vertical-align: middle;"
                                          (ngModelChange)="handleCheckboxChange()">
                                          <span style="vertical-align: middle"> 
                                            Offerings and Case Studies
                                        </span>
                                      </label>
                                  </div>
                                  </div>
  
                                  <div  class="row justify-content-center">
                                    <div class="col-md-5  d-flex">
                                      <label class="checkbox-label checkboxx">
                                        <input type="checkbox" id="uploadCheckbox" [(ngModel)]="checkboxes['accnewscheckbox']"  required
                                          [ngModelOptions]="{standalone: true}" style="margin-right: 5px; vertical-align: middle;"
                                          (ngModelChange)="handleCheckboxChange()">
                                          <span style="vertical-align: middle"> 
                                            Account News
                                        </span>
                                      </label>
                                    </div>
                                    <div class="col-md-6  d-flex">
                                      <label class="checkbox-label checkboxx">
                                          <input type="checkbox" id="uploadCheckbox" [(ngModel)]="checkboxes['oppurtunitycheckbox']" required
                                            [ngModelOptions]="{standalone: true}" style="margin-right: 5px; vertical-align: middle;"
                                            (ngModelChange)="handleCheckboxChange()">
                                            <span style="vertical-align: middle"> 
                                              Deals pursued/in pipeline
                                          </span>
                                        </label>
                                    </div>
                                    </div>
                                    <div  class="row justify-content-center">
                                      <div class="col-md-5  d-flex">
                                        <label class="checkbox-label checkboxx">
                                          <input type="checkbox" id="uploadCheckbox" [(ngModel)]="checkboxes['connectioncheckbox']"  required
                                            [ngModelOptions]="{standalone: true}" style="margin-right: 5px; vertical-align: middle;"
                                            (ngModelChange)="handleCheckboxChange()">
                                            <span style="vertical-align: middle"> 
                                              Connections
                                          </span>
                                        </label>
                                      </div>
                                      <div class="col-md-6  d-flex">
                                        <label class="checkbox-label checkboxx">
                                            <input type="checkbox" id="uploadCheckbox" [(ngModel)]="checkboxes['challengescheckbox']" required
                                              [ngModelOptions]="{standalone: true}" style="margin-right: 5px; vertical-align: middle;"
                                              (ngModelChange)="handleCheckboxChange()">
                                              <span style="vertical-align: middle"> 
                                                Account priorities & challenges
                                            </span>
                                          </label>
                                      </div>
                                      </div>
      
                                      <div  class="row justify-content-center">
                                        <div class="col-md-5  d-flex">
                                          <label class="checkbox-label checkboxx">
                                            <input type="checkbox" id="uploadCheckbox" [(ngModel)]="checkboxes['conversationcheckbox']"  required
                                              [ngModelOptions]="{standalone: true}" style="margin-right: 5px; vertical-align: middle;"
                                              (ngModelChange)="handleCheckboxChange()">
                                              <span style="vertical-align: middle"> 
                                                Last 5 SFDC conversations
                                            </span>
                                          </label>
                                        </div>

                                        <div class="col-md-6 d-flex ">
                                          <label class="checkbox-label checkboxx" >
                                            <input type="checkbox" id="uploadCheckbox" [(ngModel)]="checkboxes['acclandscapecheckbox']" required
                                              [ngModelOptions]="{standalone: true}" style="margin-right: 5px; vertical-align: middle;"
                                              (ngModelChange)="handleCheckboxChange()">
                                              <span style="vertical-align: middle"> 
                                                Account Operating Landscape
                                            </span>
                                          </label>
                                        </div>
                                        </div>
                              <div class="row justify-content-center">

                                <div class="col-md-5  d-flex">
                                  <label class="checkbox-label checkboxx">
                                      <input type="checkbox" id="uploadCheckbox" [(ngModel)]="checkboxes['competitioncheckbox']" required
                                        [ngModelOptions]="{standalone: true}" style="margin-right: 5px; vertical-align: middle;"
                                        (ngModelChange)="handleCheckboxChange()">
                                        <span style="vertical-align: middle"> 
                                          Competitor’s Contracts due for Renewal
                                      </span>
                                    </label>
                                </div>

                                <!-- Account Technology Landscape -->
                                <div class="col-md-6 d-flex ">
                                  <label class="checkbox-label checkboxx" >
                                    <input type="checkbox" id="uploadCheckbox" [(ngModel)]="checkboxes['acctechscapecheckbox']" required
                                      [ngModelOptions]="{standalone: true}" style="margin-right: 5px; vertical-align: middle;"
                                      (ngModelChange)="handleCheckboxChange()">
                                      <span style="vertical-align: middle"> 
                                        Account Technology Landscape
                                    </span>
                                  </label>
                                </div>
                                <div class="col-md-3 d-flex ">
                                </div>
                              </div>
                              <!-- Financial Benchmarking -->
                              <div  class="row justify-content-center">


                                <div class="col-md-5  d-flex">
                                  <label class="checkbox-label checkboxx">
                                    <input type="checkbox" id="uploadCheckbox" [(ngModel)]="checkboxes['financecheckbox']"  required
                                      [ngModelOptions]="{standalone: true}" style="margin-right: 5px; vertical-align: middle;"
                                      (ngModelChange)="handleCheckboxChange()">
                                      <span style="vertical-align: middle"> 
                                        Financial Benchmarking
                                    </span>
                                  </label>
                                </div>
                                <div class="col-md-6  d-flex">
                                  <label class="checkbox-label checkboxx">
                                    <input type="checkbox" id="uploadCheckbox" [(ngModel)]="selectallcheckbox" required
                                    [ngModelOptions]="{standalone: true}" (change)="checkAll()" style="margin-right: 5px; vertical-align: middle;"
                                        (ngModelChange)="handleCheckboxChange()">
                                        <span style="vertical-align: middle"> 
                                          Select all
                                      </span>
                                    </label>
                                </div>
                               
                                </div>
                            </div>
                          </div>
                            </div>
                          </div>

                          <div class="col-md-6 d-md-grid mx-2">
                            <div class="col-md-12 card" style="background-image: linear-gradient(to top, #F8F8F8, #F0F0F0)">
                              <div id="circle">2</div>
                            <div [class.disabled] ="!isAccountNeeded && !isBuyingCenterNeeded && !isChallengeNeeded" class="row justify-content-center mt-1" >
                                <div class="col-12 text-dark mt-2" style="text-align: center; font-family: Arial;" >
                                    <p style="margin-bottom: -5px !important;"><sup style="color: red; margin-right: -5px;">&#8727;</sup> Please select <b>Account Name</b> and <b>Buying Centre</b></p>
                                </div>
                            </div>
                            <div class="row justify-content-center mt-3">
                                <div [class.disabled] ="!isAccountNeeded" class="col-md-5 d-md-grid card py-3" style="background-image: linear-gradient(to bottom, #FFFFFF, #F0F0F0);">
                                    <label style="text-align: center;"><img src="../../assets/AI icon.png" style="margin-right:10px; font-family: GuardianSansRegular !important"/><b>Account Name</b></label>    
                                    <ng-multiselect-dropdown [placeholder]="'Select account name'" [settings]="dropdownSettings"
                                        [data]="accountList" [(ngModel)]="accountNameSelected" (onSelect)="onItemSelect($event)" style="background-color: white;"
                                        >
                                    </ng-multiselect-dropdown>
                                </div>
                                <!-- <div class="col-md-2"></div><br> -->
                                <div [class.disabled] ="!isBuyingCenterNeeded" class="col-md-5 d-md-grid offset-md-1 card py-3" style="background-image: linear-gradient(to bottom, #FFFFFF, #F0F0F0); margin-left: 10px;" >
                                    <label style="text-align: center;"><img src="../../assets/AI icon.png" style="margin-right:10px; font-family: GuardianSansRegular !important"/><b>Buying Centre</b></label>
                                    
                                    <ng-multiselect-dropdown [disabled]="buyingCenterList?.length == 0" [placeholder]="'Select buying centre'"
                                        [settings]="dropdownSettings" [data]="buyingCenterList" [(ngModel)]="centerSelected" style="background-color: white;">
                                    </ng-multiselect-dropdown>
                                    
                                </div>
                                </div>
                                <div class="row justify-content-center mt-3">
                                <div [class.disabled] ="!isChallengeNeeded" class="col-md-10 d-md-grid card py-3" style="background-image: linear-gradient(to bottom, #FFFFFF, #F0F0F0);;">
                                    <label style="text-align: center;"><img src="../../assets/AI icon.png" style="margin-right:10px; font-family: GuardianSansRegular !important"/><b>Find Offerings & Case Studies by Client Challenges/Topics</b></label>
                                <textarea style="border-color: #999696; border-radius: 3px;" autoResize [(ngModel)]="userInput" class="message-input" placeholder="Enter the client challenge or the topic (e.g., modernizing legacy infrastructure, cost-takeout, rewiring the supply chain, etc.)"
                                 #textArea></textarea>
                                 
                                </div>                            
                          </div>
                        </div>

                      </div>
                    </div>
                    <div class="row justify-content-center pb-5" style="margin-top: -10px;">
                      <div class="col-md-5  d-md-grid mx-3">

                      </div>
                    <div class="col-md-6 d-md-grid mx-2" style="display: flex; justify-content: flex-end; padding-left:10px ">
                        <button [disabled]="!accountNameSelected[0] && !centerSelected[0] && !userInput" class="btn btn-primary py-1 btn-sm " style="color:#073262; border-radius:none" (click)="getMeetingNotes()">
                            <span><b>Generate  </b></span><img style='width: 20%;' src="../../../../assets/enter icon.svg" /></button>
                     </div>
                    </div>
                            
                            
                          <footer class="footer">
                            <div class="container">
                              <span style="font-size: 11px;">AI-generated response may not be fully accurate, you may copy the response and check with experts for critical information.</span>
                            </div>
                          </footer>
                            
                        </ng-container>
                    
                    <!-- Chat block  End -->
                </div>

            
        
    </div>
</div>



<button type="button" class="btn btn-primary d-none" id="instructions_modal"(click)="openModal(instructions_modal)" >
</button>

<!-- instructions block -->
<ng-template #instructions_modal>
  <div class="modal-body" style="color:#073262; border-radius: 10px!important;">
    <div class="row">
      <div class="col-12 fw-bold">Important Instructions
      </div>
      <div class="row mt-2">
        <div class="col-12" style="font-size: 12px; font-family: Arial;">
        <ol style="list-style:disc;">
        <li>
            The AI-generated response will be limited to ensure concise and focused answers.
        </li>
        <li style="margin-top:5px">
          The tool may display inaccurate info. Therefore, double-check its responses for accuracy by referring to the source documents AI has referred to.
        </li>
        <li style="margin-top:5px">
        If you have any inquiries, we kindly encourage you to consult Genpact's Code of Conduct Document and ChatGPT and Use of AI Programs Policy.
        </li>
        <li style="margin-top:5px; margin-bottom: -15px;">
        Kindly note that your inputs maybe subject to audit, and failure to comply with the guidelines may result in disciplinary action.
         </li>
        </ol></div>
      </div>


    <div class="">
      <div class="">
        <input id="instructions_check_label" type="checkbox" [(ngModel)]="chkagree" class="form-check-input" style="background-color:#073262 !important"/>
        <label class="form-check-label fw-bold text-primary"
        style="
        margin: 0;
        font-weight: bold;
        font-size: 14px !important;
        color: #073262 !important;
        font-family: GuardianSansRegular !important;
        padding-left: 20px;"
          for="instructions_check_label">
          I acknowledge that I have read, understand and agree to above instructions
        </label>
      </div>
    </div>
  </div>
  <div class="col-12 mt-1" style="display: flex; justify-content:space-around;">
    <button type="button" class="btn button-genpact text-white center cursor-pointer text-center" 
      (click)="modalRef?.hide()">
      Proceed
    </button>
  </div>
  </div>
</ng-template>


<ng-template #like_modal>
    <div class="modal-body">
        <div class="row">
    <div class="col-12 fw-bold text-primary text-center my-2">Provide Additional Feedback
        <button type="button" class="close" data-dismiss="modal" (click)="modalRef?.hide()"><img src="../../../../assets/crossicon.png"></button>
    </div>
            <div class="col-12 my-2" style="font-size: 12px;">
              <textarea class="form-control" type="textarea" placeholder="Please provide your feedback here..." style="height: 100px" [(ngModel)]="likefeedback"></textarea>
            </div>
<div class="col-12" style="display: flex; justify-content:space-around;">
  <button type="button" class="btn button-genpact text-white center cursor-pointer text-center" 
    (click)="modalRef?.hide(); feedbackSubmit()">
    Submit
  </button>
</div>
</div>
</div>
  </ng-template>

  <ng-template #unlike_modal>
    <div class="modal-body">
        <div class="row">
    <div class="col-12 fw-bold text-primary text-center my-2">Provide Additional Feedback
        <button type="button" class="close" data-dismiss="modal" (click)="modalRef?.hide()"><img src="../../../../assets/crossicon.png"></button>
    </div>
            <div class="col-12 my-2" style="font-size: 12px;">
              <textarea class="form-control" type="textarea" placeholder="Please provide your feedback here..." style="height: 60px" [(ngModel)]="unlikefeedback"></textarea>
            </div>

            <div class="checkbox-container col-12" style="justify-content: space-between;">
                <label>
                  <input type="checkbox" name="checkbox1" [(ngModel)]="harmfulcheckbox"> This is harmful
                </label>
                <br>
                <label>
                  <input type="checkbox" name="checkbox2" [(ngModel)]="incorrectcheckbox"> This is incorrect
                </label>
                <br>
                <label>
                  <input type="checkbox" name="checkbox3" [(ngModel)]="nothelpfulcheckbox"> This is not helpful
                </label>
              </div>
<div class="col-12" style="display: flex; justify-content:space-around;">
  <button type="button" class="btn button-genpact text-white center cursor-pointer text-center" 
    (click)="modalRef?.hide(); feedbackSubmit()">
    Submit
  </button>
</div>
</div>
</div>
</ng-template>

<ngx-spinner bdColor = "rgba(0, 0, 0, 0.9)" size = "medium" color = "#00aecf" type = "timer">
  <p style="font-size: 16px; color:white; font-family: Arial !important; text-align: center"><br><br><br>
    Please wait while we prepare your meeting note.<br> Why don't you grab a cuppa, this may take some time.
  </p>
  </ngx-spinner>

  <div class="col-12 p-5 text-center" *ngIf="!userAccessStatus">
    <div class="row  mt-5">
      <div class="col-12">
        <h5 class="display-12 pl-5 headtext">
          User doesn't have permission to access MeetingAssist.
        </h5>
  
      </div>
    </div>
  </div>