import { Component, ElementRef, OnInit, TemplateRef } from '@angular/core';
import { BgcService } from 'src/app/shared/service/bgc.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';
//import { VoiceRecognitionService } from 'src/app/shared/voice-recognition.service';
//import { Clipboard } from '@angular/cdk/clipboard';
import { Router } from '@angular/router';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { ViewChild } from '@angular/core';
import { PageEvent } from '@angular/material/paginator';
//import { MatTableDataSource } from '@angular/material/table';
//import { MatPaginator } from '@angular/material/paginator';
@Component({
  selector: 'app-bgc-list',
  templateUrl: './bgc-list.component.html',
  styleUrls: ['./bgc-list.component.scss']
})
export class BgcListComponent implements OnInit {
  batch: boolean = false;
  //list: any;
  inputtask: any;
  inputname: any;
  // fileName: any;

  pendingpid: string = 'true';
  key: string = '';
  selectedOption: any = {};

  filexport: any;
  exportbutton: boolean = false;

  result: string = 'result';
  pending: string = 'pending';
  uploadedFileName: string = "";

  modalRef?: BsModalRef;
  selectedProcessId: any;
  fileType: string = 'xlsx';
  processType: string = 'result';
  phaseType: string = 'phase1';
  expectedFilenames: string[] = ['MSAChecklist.xlsx', 'Population.xlsx', 'Address.xlsx', 'Education.xlsx', 'Employment.xlsx', 'GenericChecks.xlsx', 'Consolidation.xlsx', 'HRSS.xlsx', 'TYDY.xlsx'];

  displayedColumns: string[] = [
    'id',
    'processId',
    'files',
    'region',
    'taskName',
    'testingType',
    'createdOn',
    'updatedOn',
    'status',
    'action',
    'download',
  ];
  link: any;
  searchTerm: string = '';
  list: any[] = [];  // Assuming 'list' is your original data array
  filteredList: any[] = [];
  // dataSource = new MatTableDataSource<any>([]);
  // @ViewChild(MatPaginator) paginator!: MatPaginator;
  isAscending: boolean = true;

  constructor(

    private loaderService: LoaderService,
    private toastMessage: ToastMessageService,
   // public speechservice: VoiceRecognitionService,

    private bgcService: BgcService,
    private router: Router,
    private modalService: BsModalService
  ) {
this.pageSize = 5;
this.currentPage = 1;
  }
  pageSize:any = 10;
  currentPage = 1;
  showFirstLastButtons = false;
  hidePageSize = true;

  // Method to handle page change
  onPageChange(event: PageEvent): void {
    if (this.pageSize === 'All') {
      // Return the entire dataset without filtering
      this.filteredList = this.list.slice();
      this.pageSize = this.list.length; // Set pageSize to the total length of your dataset
      this.currentPage = 1; // Set currentPage to 1 as there's only one page when displaying all records
    } else {
      // Handle logic for other page sizes
      this.currentPage = event.pageIndex + 1;
      this.pageSize = event.pageSize;
      // Implement your filtering or data handling logic based on the selected pageSize
    }
  }
  filname(file: any): boolean {
    if (file == '-') {
      console.log('works');
      return false;
    }
    return true;
  }

  ngOnInit(): void {
    this.bgclist();
    // this.loadData();
  }
  set(){
    localStorage.setItem('taskname', 'not new hire')
    this.router.navigate(['bgc/init']);

  }

  getprocessStage(id: any,task:any,stage:any){

    localStorage.setItem('taskname', task)
    localStorage.setItem('stage',stage)

    this.loaderService.loading.next(true);
this.bgcService.fatchBGC(id).subscribe((response: any) => {

  if(response.status == "success"){
    this.router.navigate(['bgc/' + id, 'upload']);
    this.toastMessage.showSuccess(response.message);
    this.loaderService.loading.next(false);
  }
  else{
    this.loaderService.loading.next(false);
    this.toastMessage.showError(response.message);
  }

});

  }

  getprocessId(id: any,task:any) {
    localStorage.setItem('taskname', task)

    this.loaderService.loading.next(true);
this.bgcService.fatchBGC(id).subscribe((response: any) => {

  if(response.status == "success"){
    this.router.navigate(['bgc/' + id, 'upload']);
    this.toastMessage.showSuccess(response.message);
    this.loaderService.loading.next(false);
  }
  else{
    this.loaderService.loading.next(false);
    this.toastMessage.showError(response.message);
  }

});

  }
  // ngAfterViewInit() {
  //   if (this.paginator) {
  //     this.dataSource.paginator = this.paginator;
  //   }
  // }

  // applyFilter(event: any) {
  //   const filterValue = (event.target as HTMLInputElement).value.trim().toLowerCase();
  //   this.dataSource.filter = filterValue;
  //   if (this.dataSource.paginator) {
  //     this.dataSource.paginator.firstPage();
  //   }
  // }


  // loadData() {
  //   this.loaderService.loading.next(true);
  //   this.bgcService.bgclist().subscribe(
  //     (response: any) => {
  //       if (response.status === 'success') {
  //         if (response.data.length > 0) {
  //           this.loaderService.loading.next(false);
  //           this.dataSource = new MatTableDataSource(response.data);
  //           this.dataSource.paginator = this.paginator;
  //         } else {
  //           this.loaderService.loading.next(false);
  //           this.toastMessage.showWarning('No data found');
  //         }
  //       } else {
  //         this.loaderService.loading.next(false);
  //         this.toastMessage.showError('No data found');
  //       }
  //     },
  //     (error) => {
  //       this.loaderService.loading.next(false);
  //       console.error(error);
  //       this.toastMessage.showError(error?.message);
  //     }
  //   );
  // }
  // Add these properties and methods to your component
jumpToPage: number = 1;

onJumpToPage() {
  // Ensure the entered page is within the valid range
  if (this.jumpToPage >= 1 && this.jumpToPage <= this.list.length / this.pageSize) {
    this.currentPage = this.jumpToPage;
    this.filterList(); // Update the filtered list based on the new page
  } else {
    // Handle invalid page number (you can show a message or perform some action)
    console.warn('Invalid page number!');
  }
}

  toggleSortOrder() {
    this.isAscending = !this.isAscending;
    this.sortList(); // Call your sorting function here based on the index
  }
  // sortList() {
  //   const sortOrder = this.isAscending ? 1 : -1;

  //   this.filteredList.sort((a, b) => {
  //     const indexA = this.list.indexOf(a);
  //     const indexB = this.list.indexOf(b);

  //     if (indexA < indexB) {
  //       return -1 * sortOrder;
  //     } else if (indexA > indexB) {
  //       return 1 * sortOrder;
  //     } else {
  //       return 0;
  //     }
  //   });
  // }
  validatebgc(pid: any,mode:string) {
    // this.loaderService.loading.next(true);
     this.loaderService.loading.next(true);
    // this.toastMessage.showmessage("Validating file");
     const payload = {
       "mode": mode,    //mode:"start/stop/delete"
     }

     this.bgcService.bgcManage(payload, pid).subscribe((response: any) => {

       if (response.status == "success") {

       //  this.toastMessage.showSuccess(response.message);
         this.loaderService.loading.next(false);
       }
       else {

         this.toastMessage.showError(response.message);
         //this.manageBGC(pid,"start");
         this.loaderService.loading.next(false);
       }
     }, (error) => {
       this.loaderService.loading.next(false);
       this.toastMessage.showWarning(error?.error?.error);
       this.toastMessage.showError(error?.error?.error_message);
       //this.manageBGC(pid,"start");
     });
   }
  sortList() {
    // Create a copy of the original list
    const originalListCopy = [...this.list];
    const sortOrder = this.isAscending ? 1 : -1;

    this.filteredList.sort((a, b) => {
      return (originalListCopy.indexOf(a) - originalListCopy.indexOf(b)) * sortOrder;
    });

    // After sorting, update the indices in the filteredList
    this.filteredList.forEach((item, index) => {
      item.index = index + 1;
    });
  }

  calculateDisplayIndex(originalIndex: number): number {
    const startIndex = (this.currentPage - 1) * this.pageSize;
    const totalElements = this.list.length;
// console.log('startIndex',startIndex);
// console.log('totalElements',totalElements);
// console.log('originalIndex',originalIndex);
// console.log('this.isAscending',this.isAscending);
    return this.isAscending
      ? originalIndex + 1 + startIndex
  : totalElements - (originalIndex + 1 + startIndex) + 1;
  }










  clearSearch() {
    this.searchTerm = '';
    this.applySearch(); // Optionally, you can trigger the search immediately after clearing.
  }

  getPercentComplete(file: any) {
    return Math.round((file.completed / file.total * 100)) || 0 + '';
  }

  bgclist() {
    this.loaderService.loading.next(true);
    this.bgcService.bgclist()
      .subscribe(
        (response: any) => {
          console.log(response);

          if (response.status == "success") {
            if (response.data.length > 0) {
              this.loaderService.loading.next(false);
              this.list = response.data;

              // Apply search filter if searchTerm is not empty
              if (this.searchTerm.trim() !== '') {
                this.filterList();
              } else {
                // If searchTerm is empty, set filteredList to the entire list
                this.filteredList = this.list.slice();
              }
            } else {
              this.loaderService.loading.next(false);
              this.toastMessage.Showhome(response.error_message);
            }
          } else {
            this.loaderService.loading.next(false);
            this.toastMessage.Showhome(response.error_message);
          }
        },
        (error) => {
          this.loaderService.loading.next(false);
          console.error(error);
          this.toastMessage.showError(error?.error?.error_message);
        }
      );
  }
  loadData() {
    this.loaderService.loading.next(true);
    this.bgcService.bgclist().subscribe(
      (response: any) => {
        if (response.status === 'success') {
          if (response.data.length > 0) {
            this.loaderService.loading.next(false);
            this.list = response.data;
            this.filteredList = this.list.slice(); // Initialize filteredList with the entire dataset
          } else {
            this.loaderService.loading.next(false);
            this.toastMessage.showWarning('No data found');
          }
        } else {
          this.loaderService.loading.next(false);
          this.toastMessage.showError('No data found');
        }
      },
      (error) => {
        this.loaderService.loading.next(false);
        console.error(error);
        this.toastMessage.showError(error?.message);
      }
    );
  }
  // Function to filter the list based on the search term
  filterList() {
    this.filteredList = this.list.filter((item) => {
      // Implement your own search logic here
      const searchTermLower = this.searchTerm.toLowerCase();
      const processIdStr = item.processId.toString().toLowerCase(); // Convert processId to lowercase string

      return (
        item.region.toLowerCase().includes(searchTermLower) ||
        item.testingType.toLowerCase().includes(searchTermLower) ||
        //(isNaN(item.processId) ? processIdStr.includes(searchTermLower) : item.processId.toString().includes(searchTermLower)) ||
        (item.createdByOHR && item.createdByOHR.toString().includes(searchTermLower)) ||
        (item.createdBy && item.createdBy.toLowerCase().includes(searchTermLower)) || // Add username search
         (item.taskName && item.taskName.toLowerCase().includes(searchTermLower)) || // Add taskName search
         (item.status && item.status.toLowerCase().includes(searchTermLower))|| // Add status search
         (item.processId && item.processId.toLowerCase().includes(searchTermLower))
         //for status

        // Add more fields as needed for your search
      );
    });
  }




  // Function to apply search when the user types in the search input
  applySearch() {
    console.log('Applying search with searchTerm:', this.searchTerm);

    // Always reset the current page to 1 when searching
    this.currentPage = 1;

    this.filterList();
  }


  startNewBatch1(proId: any) {
    this.router.navigate(['bgc/' + proId, 'upload']);
  }


  openModal(template: TemplateRef<void>, id: any,size='modal-md') {
    this.selectedProcessId = id;
    this.modalRef = this.modalService.show(template, {class:size, ignoreBackdropClick: true });
  }

  downloadreport(pid: any, fprocess: string) {
    const payload = {
      "file": fprocess,
      "format": ".xlsx"
    }

    this.bgcService.bgcdownload(payload, pid).subscribe((response: any) => {
      // this.toastMessage.showSuccess("Starting File Export");
      console.log(response);
      if (response.status == "success") {
        this.toastMessage.showSuccess(response.message);
        this.filexport = response.url;
        this.exportbutton = true;
        console.log(this.filexport);
        this.downloadExcelFile(this.filexport);
        // window.location.href = this.filexport;
      }
      else {
        this.toastMessage.showError(response.message);
      }
    }, (error) => {
      this.loaderService.loading.next(false);
    });

  }

  

  onFileSelected(event: any) {
    const file = event.target.files[0];
    if (file) {
      this.uploadedFileName = file.name;
    }
  }

  uploadFile(form: any) {
    // Upload file logic
  }

  downloadExcelFile(url: string) {
    // Show loading indicator
    this.loaderService.loading.next(true);

    // Show a toast message indicating that the file is being downloaded
    this.toastMessage.showmessage("Your file is being downloaded");

    // Use Fetch API to get the file as a blob
    fetch(url)
      .then(response => {
        // Check if the response is successful
        if (!response.ok) {
          throw new Error(`Failed to fetch: ${response.status} ${response.statusText}`);
        }
        return response.blob();
      })
      .then(blob => {
        // Hide loading indicator
        this.loaderService.loading.next(false);

        // Extract filename from the URL
        const urlWithoutQuery = url.split('?')[0];
        const urlParts = urlWithoutQuery.split('/');
        const filenameFromUrl = urlParts[urlParts.length - 1];

        // If filename is not present in the URL, use a default filename
        const finalFilename = filenameFromUrl ? filenameFromUrl : 'default_filename.xlsx';

        // Create a temporary link element
        const a = document.createElement('a');

        // Create a URL for the blob
        const objectURL = URL.createObjectURL(blob);

        // Set link properties
        a.href = objectURL;
        a.download = finalFilename;

        // Append the link to the document body, trigger a click, and then remove the link
        document.body.appendChild(a);
        a.click();
        URL.revokeObjectURL(objectURL);
        document.body.removeChild(a);
      })
      .catch(error => {
        // Hide loading indicator
        this.loaderService.loading.next(false);

        // Show an error toast message
        this.toastMessage.showError('Error downloading Excel file: ' + error);
        console.error('Error downloading Excel file:', error);
      });
  }




  getFilesLeftCount(data: any) {
    if (data) return data.split(',');
    return [];
  }

  fileCount(data: any) {

    if (data) {
      let filelength = data.split(',').length || 0;
      return 9 - filelength;
    }
    return 9;
  }
  fileCount1(data: any, task: any) {
    console.log(task)
    const x = (task === 'New Hire') ? 3 : 9;
  
    if (data) {
      const filelength = data.split(',').length || 0;
      return x - filelength;
    }
    return x;
  }



  manageBGC(pid: any, mode: string) {
    // if(mode == "start"){
    //   this.validatebgc(pid,"validate");
    // }
    this.loaderService.loading.next(true);
    const payload = {
      "mode": mode,    //mode:"start/stop/delete"
    }

    this.bgcService.bgcManage(payload, pid).subscribe((response: any) => {
      this.loaderService.loading.next(false);
      if (response.status == "success") {
        this.toastMessage.showSuccess(response.message);
        this.bgclist();
      }
      else {
        this.toastMessage.showError(response.message);
      }
    }, (error) => {
      this.loaderService.loading.next(false);
      this.toastMessage.showWarning(error?.error?.error);
      this.toastMessage.showError(error?.error?.error_message);
    });
  }






  deleteBGC() {

    this.modalRef?.hide();

    this.loaderService.loading.next(true);
    this.bgcService.deleteProcess(this.selectedProcessId).subscribe((response: any) => {
      this.loaderService.loading.next(false);
      if (response.status == "success") {
        this.toastMessage.showSuccess(response.message);
        this.bgclist();
      }
      else {
        this.toastMessage.showError(response.message);
      }

      this.selectedProcessId = '';
    }, (error) => {
      this.loaderService.loading.next(false);
    });

  }

  //export file fuintion
  exportFile(data: any) {
    console.log(data.value);
    this.modalRef?.hide();
    this.loaderService.loading.next(true);
    const payload = {
      file: data.value.processType,  // "file":"msa/result/pending/filename",
      format: data.value.fileType // "format":"excel/json"
    }

    this.bgcService.bgcdownload(payload, this.selectedProcessId).subscribe((response: any) => {
      this.loaderService.loading.next(false);
      if (response.status == "success") {
        // this.toastMessage.showSuccess(response.message);
        this.link = response.url;

        this.downloadExcelFile(this.link);
        // this.bgclist();
      }
      else {
        this.toastMessage.showError(response.message);
      }
    }, (error) => {
      this.loaderService.loading.next(false);
    });

  }





}
