// import { UsermanageComponent } from './usermanage/usermanage.component';
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
// import { HomeComponent } from './home/home.component';
import { UnauthorizedComponent } from './unauthorized/unauthorized.component';
import { AuthGuard } from './auth.guard';
import { LogoutComponent } from './logout/logout.component';
import { OktaCallbackComponent } from '@okta/okta-angular';
import { SocReportComponent } from './soc-report/soc-report.component';
import { BgcComponent } from './bgc/bgc.component';
import { BgcInitComponent } from './bgc/bgc-init/bgc-init.component';
import { BgcListComponent } from './bgc/bgc-list/bgc-list.component';
import { LoginComponent } from './login/login.component';
import { HomeComponent } from './home/home.component';
import { HomeKonverseComponent } from './home-konverse/home-konverse.component';
import { LandingPageComponent } from './landing-page/landing-page.component';
import { MeetingAssistComponent } from './meeting-assist/meeting-assist.component';
import { PdfsummaryComponent } from './Document Summarisation/pdfsummary/pdfsummary.component';
import { LayoutComponent } from './shared/components/layout/layout.component';
import { HomeResolver } from './shared/reslovers/home-reslover.service';
import { PlatformCheckComponent } from './platform-check/platform-check.component';
import { TeamsauthComponent } from './teamsauth/teamsauth.component';
import { GchatComponent } from './gchat/gchat.component';
import { KonverseAIComponent } from './konverse-ai/konverse-ai.component';
import { KonverseAdhocComponent } from './konverse-adhoc/konverse-adhoc.component';
import { KonverseBulkComponent } from './konverse-bulk/konverse-bulk.component';
import { KonverseQuestionsComponent } from './konverse-questions/konverse-questions.component';
import { KonverseitsolutionsComponent } from './konverseitsolutions/konverseitsolutions.component';
 

const routes: Routes = [


  { path: '', component: PlatformCheckComponent, resolve: { data: HomeResolver } ,
  children:[
    {path: '', component: HomeComponent},
    { path: 'home', component: OktaCallbackComponent },
    { path: 'newhome', component: HomeKonverseComponent },
    { path: 'user', component: OktaCallbackComponent },
    { path: 'login', component: LoginComponent },
    { path: 'Home', component: LandingPageComponent, canActivate: [AuthGuard] },
  
    { path: 'logout', component: LogoutComponent },
  
    { path: 'soc-report', component: SocReportComponent, canActivate: [AuthGuard] },
    {
      path: 'bgc', component: BgcComponent, children: [
        // { path: '', redirectTo: 'list', pathMatch: 'full' },
        { path: '', component: BgcListComponent, canActivate: [AuthGuard] },
        { path: 'init', component: BgcInitComponent, canActivate: [AuthGuard] },
        { path: ':id/upload', component: BgcInitComponent, canActivate: [AuthGuard] },
        { path: ':id/upload/:stage', component: BgcInitComponent, canActivate: [AuthGuard]}
      ]
    },
    { path: 'meeting-assist', component: MeetingAssistComponent, canActivate: [AuthGuard] },
  
    { path: 'invalidAuth', component: TeamsauthComponent },
  
    { path: 'unauthorized', component: UnauthorizedComponent },
    { path: 'document-summary', component: PdfsummaryComponent, canActivate: [AuthGuard] },
    { path: 'g-chat', component: GchatComponent, canActivate: [AuthGuard]},
 
    {  path: 'konverse-ai', component: KonverseAIComponent, canActivate: [AuthGuard] },
    {  path: 'konverse-adhoc', component: KonverseAdhocComponent, canActivate: [AuthGuard] },
    {  path: 'konverse-bulk', component: KonverseBulkComponent, canActivate: [AuthGuard] },
    {  path: 'konverse-questions', component: KonverseQuestionsComponent, canActivate: [AuthGuard] },
    {  path: 'konverseitsolutions', component: KonverseitsolutionsComponent, canActivate: [AuthGuard] }
  
  ]

},
  


  // {
  //   path: '', component: ConverseAiComponent, children: [
  //     { path: 'sop-tool', component: SopToolComponent, canActivate: [AuthGuard] },
  //     { path: 'sop-upload', component: SopUploadComponent, canActivate: [AuthGuard] },
  //     { path: 'sop-chatbot', component: SopChatbotComponent, canActivate: [AuthGuard] },
  //     { path: 'sop-previousresponses', component: SopPreviousresponsesComponent, canActivate: [AuthGuard] },
  //     { path: 'konverse', component: ConverseComponent, canActivate: [AuthGuard] },
  //     { path: 'sop-file-upload', component: SopUploadBotdocComponent, canActivate: [AuthGuard] },
  //     { path: 'soc-report', component: SocReportComponent, canActivate: [AuthGuard] },


  //     { path: 'unauthorized', component: UnauthorizedComponent, canActivate: [AuthGuard] },
  //   ]
  // },
];


@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
  providers: [HomeResolver]
})
export class AppRoutingModule { }
