<app-sidenav ></app-sidenav>

<div class="col-12">
    <div class="row  mt-5">
      <div class="col-12">
        <h5 class="display-12 text-left pl-0 headtext" style="padding-left: 9px!important;">Background check validation</h5>

      </div>
    </div>
  </div>
  <div class="col-12 p-5 text-center" *ngIf="!userAccessStatus">
    <div class="row  mt-5">
      <div class="col-12">
        <h5 class="display-12 pl-5 headtext">
          {{placeholder}}
        </h5>

      </div>
    </div>
  </div>



<router-outlet *ngIf="userAccessStatus"></router-outlet>
<!-- <router-outlet ></router-outlet> -->

<!-- <app-app-footer></app-app-footer> -->
<br>
<div class="footer">
footer
</div>
