<div class="container-fluid responsive px-3 " >

  <!-- //dynamic table style="justify-content: flex-end;align-items: flex-end;"-->
  <div class="d-flex py-0" style="    align-content: center;
  flex-wrap: wrap;
  justify-content: flex-end;
  align-items: baseline;">

    <div class="text-left">

      <div class="search-bar">
        <label title="search" for="searchInput"></label>
        <!-- <div class="input-group">
          <input style="border-radius: 0px; padding-right: 30px;" type="text" id="searchInput" [(ngModel)]="searchTerm" (input)="applySearch()" class="form-control" placeholder="search" />
          <div class="input-group-append" style="position: absolute; right: 0; top: 0; bottom: 0; display: flex; align-items: center;">
            <button *ngIf="searchTerm" style="border-radius: 0px; border-left: none; border-color: #dee2e6;" class="btn btn-outline-secondary" type="button" (click)="clearSearch()">
              <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="#FF555F" class="bi bi-x" viewBox="0 0 16 16">
                <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708"/>
              </svg>
            </button>
          </div>
        </div> -->
        <div class="row">
          <div class="input-group ">
              <input title="search" class="form-control py-2  border" style="border-radius:0px;" type="search" value="Search" id="example-search-input" placeholder="Search" [(ngModel)]="searchTerm" (input)="applySearch()">
              <span class="input-group-append">
                  <button style="display:none" class="btn btn-outline-secondary border-left-0 border" type="button">
                      <i class="fa fa-times"></i>
                  </button>
                </span>
          </div>
      </div>


      </div>


    </div>
    <div class=" my-3 text-center" style="padding-left: 6px;">
      <button (click)='set()' type="button" class="btn btn-primary"  style="font-family: georgia;height: 38px;
      margin-top: -5px;">Upload new
        batch </button>
    </div>

    <div class="text-right" style="padding-left: 5px;">
      <button id="refreshbtn" class="bg-primary text-light p-2 px-4 border border-r1" style="background-color: #021D44!important;
      color: #ffffff!important;
      border: none!important;" (click)="bgclist()">
        <i class="fa fa-refresh"></i>
      </button>
    </div>

  </div>
  <div class="table-responsive" style="font-size: 12px;">


    <table class="table  table-bordered  pl-5 align-middle">

      <thead>
        <tr>
          <th scope="col">#
            <button  class="btn btn-link p-0" style="color:#ffffff" (click)="toggleSortOrder()">
              <i class="fa fa-sort"></i>
            </button>
<!--   <i class="fa" [ngClass]="{'fa-sort-amount-asc': isAscending, 'fa-sort-amount-desc': !isAscending}"></i>-->
          </th>
          <th scope="col">Region</th>
          <th scope="col"style="width:100px">Testing Type</th>
          <th scope="col" style="width:90px;">Type</th>
          <th scope="col" >Process Id</th>
          <th scope="col" style="width:200px">Files</th>



          <th scope="col">Start Date</th>
          <th scope="col">End Date</th>
          <th  style="width: 150px;" scope="col">Status</th>
          <th scope="col" style="width: 44px;">Action</th>
          <th scope="col">Download</th>
          <th scope="col" style="width:90px;">Run By</th>
          <th style="width:90px;">Records</th>
        </tr>
      </thead>
      <tbody>
        <tr *ngFor="let fileName of filteredList.slice((currentPage - 1) * pageSize, currentPage * pageSize); let i = index">

          <td>{{ calculateDisplayIndex(i) }}</td>
          <!-- <td style>{{fileName.id}}</td> -->
          <td>{{ fileName.region }}</td>
          <td >{{ fileName.testingType }}</td>
          <td >{{ fileName.taskName }} <br> {{ fileName.stage}}</td>

         <!-- Export file modal -->

          <ng-template #exportFileModel>
            <div class="modal-header">
              <h4 class="modal-title pull-left">Export File </h4>
              <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
                <span aria-hidden="true" class="visually-hidden">&times;</span>
              </button>
            </div>
            <div class="modal-body">
          
              <form #myForm="ngForm" (ngSubmit)="exportFile(myForm)">
                <div class="modal-body">
          
                  <div class="form-group">
                  
                  <div class="form-group py-3" *ngIf="fileName.taskName==='New Hire'">
                    <label>Phase</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="phaseType" id="phase1" value="phase1" ngModel
                          [(ngModel)]="phaseType" checked required>
                          <label class="form-check-label" for="phase1"> Phase 1 </label>
                        </div>
                      </div>
          
                      <div class="col-auto">
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="phaseType" id="phase2" value="phase2"
                          [(ngModel)]="phaseType" checked ngModel required>
                          <label class="form-check-label" for="phase2">Phase 2</label>
                        </div>
                      </div>
                      
                    
                    </div>
                  </div>
          
          
                    <label>Reconciliation Results:</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="processType" id="validResuts" value="result" ngModel
                          [(ngModel)]="processType" checked required>
                          <label class="form-check-label" for="validResuts"> Valid Candidates </label>
                        </div>
                      </div>
                      <div class="col-auto">
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="processType" id="invalidFields" value="pending"
                          [(ngModel)]="processType" checked ngModel required>
                          <label class="form-check-label" for="invalidFields">Invalid Candidates</label>
                        </div>
                      </div>
          
                      <div class="col-auto">
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="processType" id="usedMsaFields" value="msa" ngModel
                          [(ngModel)]="processType" checked  required>
                          <label class="form-check-label" for="usedMsaFields">Used MSA</label>
                        </div>
                      </div>
                      <!-- Add more radio options as needed -->
                    </div>
                  </div>
          
                  <div class="form-group py-3">
                    <label>File Type:</label>
                    <div class="row">
                      <div class="col-auto">
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="fileType" id="xlsxfeild" value="xlsx" ngModel checked
                          [(ngModel)]="fileType" checked required>
                          <label class="form-check-label" for="xlsxfeild"> XLSX</label>
                        </div>
                      </div>
                      <div class="col-auto">
                        <div class="form-check">
                          <input class="form-check-input" type="radio" name="fileType" id="jsonfeild" value="json" ngModel
                          [(ngModel)]="fileType" required>
                          <label class="form-check-label" for="jsonfeild">JSON</label>
                        </div>
                      </div>
                      <!-- Add more radio options as needed -->
                    </div>
                  </div>
          
          
          
                  <button type="submit" class="btn btn-primary mt-4" [disabled]="myForm.invalid">Export</button>
                </div>
              </form>
          
            </div>
          </ng-template>

          <td>
            <p> {{ fileName.processId }}</p>
          </td>
          <td class="links-download">

            {{ fileCount(fileName?.files) != 9 && filname(fileName?.files) ? "" : 'No File Uploaded' }}
          <div >
            <a title="Click to download file" href="javascript:;" (click)="downloadreport(fileName.processId,name)"
              *ngFor="let name of getFilesLeftCount(fileName?.files)">
              {{ name }},
            </a>
          </div>
            <small  class="text-danger d-block" *ngIf="fileCount1(fileName?.files,fileName.taskName)&& filname(fileName?.files) "> {{fileCount1(fileName?.files,fileName.taskName)}} other
              files left</small>

          </td>



          <td>
            <p style="width: 75px;">{{ (fileName.createdOn + 'Z' | date:'yyyy-MM-ddTHH:mm:ssZ':'GMT') | date:'yyyy-MM-dd, hh:mm:ss a':'IST' }}</p>
          </td>
          <td>
           <p style="width: 75px;"> {{ (fileName.updatedOn + 'Z' | date:'yyyy-MM-ddTHH:mm:ssZ':'GMT') | date:'yyyy-MM-dd, hh:mm:ss a':'IST' }}</p>
          </td>


          <td>
            <div [title]="
              (fileName.status !== 'INITIATED' && fileName.status !== 'UPLOADING' && fileName.startedOn && fileName.completedOn) ?
                'Started on: ' + ((fileName.startedOn + 'Z') | date:'yyyy-MM-ddTHH:mm:ssZ':'GMT' | date:'yyyy-MM-dd, hh:mm:ss a':'IST') +
                '\nCompleted on: ' + ((fileName.completedOn + 'Z') | date:'yyyy-MM-ddTHH:mm:ssZ':'GMT' | date:'yyyy-MM-dd, hh:mm:ss a':'IST') :
                ''">
              {{ fileName.status }}
            </div>







            <!-- <small class="text-danger d-block" *ngIf="fileName.status == 'COMPLETED'">
              process started :
              <p style="width: 75px;">{{ (fileName.startedOn + 'Z' | date:'yyyy-MM-ddTHH:mm:ssZ':'GMT') | date:'yyyy-MM-dd, hh:mm:ss a':'IST' }}</p>

            </small> -->
            <!-- <small class="text-danger d-block" *ngIf="fileName.status == 'COMPLETED'">
            process completed:
              <p style="width: 75px;">{{ (fileName.completedOn + 'Z' | date:'yyyy-MM-ddTHH:mm:ssZ':'GMT') | date:'yyyy-MM-dd, hh:mm:ss a':'IST' }}</p>

            </small> -->
            <small class="text-success d-block" *ngIf="fileName.status == 'COMPLETED'">
              <b>Remarks:
              {{fileName.remarks}}</b>
            </small>

            <small class="text-danger d-block" *ngIf="fileName.status == 'FAILED'">

           Remarks:
            <div style="font-size:12px">{{fileName.remarks}}</div>

            </small>


            <small class="text-danger d-block" *ngIf="fileName.status == 'PROCESSING'">
              {{getPercentComplete(fileName)}}% Completed
            </small>
            <br>

            <button type="button" class="d-flex px-2 py-1 btn btn-primary" style="width: 79px;font-size: 12px;justify-content: flex-start;justify-items: center;display: flex !important;align-content: space-around; padding-right: 2px !important;"
              [disabled]="(fileName.status == 'PROCESSING' || fileName.status == 'UPLOADING' || fileName.status == 'DELETED')" *ngIf="fileName.taskName==='New Hire'"
             (click)="getprocessStage(fileName.processId,fileName.taskName,fileName.stage)"
              ><i class="fa fa-upload" aria-hidden="true" style=" display: flex;
              flex: 0;
              padding-top: 3px;
              align-items: center;
              align-content: flex-start;"></i> <span style="flex:1">Stage 2</span> </button>

          </td>
          <td >
            <div style="text-align: center;vertical-align: middle;display: grid;gap: 8px;align-content: stretch;justify-items: center;">
            <!-- <p *ngIf="fileName.status == 'INITIATED'">Start Uploading files</p>
            <p *ngIf="fileName.status == 'UPLOADING'">Complete Uploading files</p> -->
            <!-- completed stage 1 should also be condition -->
            
            <button type="button" class="btn btn-primary" style="width: 48px;font-size: 12px;"
              [disabled]="(fileName.status == 'PROCESSING' || fileName.status == 'COMPLETED' || fileName.status == 'DELETED')"
             (click)="getprocessId(fileName.processId,fileName.taskName )"
              >Edit</button>
              <!-- [routerLink]="['./'+fileName.processId,'upload']" -->
            <button style="border-radius:0px;width: 48px;font-size: 12px;" [disabled]="!(fileName.status == 'UPLOADED' || fileName.status == 'STOPPED' || fileName.status == 'VALIDATED') || fileName.status == 'DELETED'"
              *ngIf="(fileName.status != 'PROCESSING' && fileName.status !== 'FAILED')" type="button" class="btn btn-success"
              (click)="manageBGC(fileName.processId,'start')">Start</button>
              <button style="border-radius:0px;width: 48px;font-size: 12px;" *ngIf="fileName.status == 'FAILED'" type="button" class="btn btn-success"
              (click)="manageBGC(fileName.processId,'restart')">Retry</button>
            <button style="border-radius:0px;width: 48px;font-size: 12px;" *ngIf="fileName.status == 'PROCESSING'" type="button" class="btn btn-warning"
              (click)="manageBGC(fileName.processId,'stop')">Stop</button>

            <!-- <button *ngIf="fileName.status == 'COMPLETED'" type="button" class="btn btn-success"
              (click)="downloadreport(fileName.processId,pending)">EXPORT pending</button> -->
              </div>
          </td>

          <!--   [disabled]="fileName.status !='COMPLETED'" -->
          <td >
           <div style="text-align: center; display:grid; gap :8px;">
            <button style="width: 44px;font-size: 12px;" title="Export process" type="button" class="btn btn-primary"
            [disabled]="fileName.status !='COMPLETED' "
            (click)="openModal(exportFileModel, fileName.processId)">
            <i class="fa fa-download" aria-hidden="true"></i>
          </button>
          <button style="border-radius:0px;width: 44px;font-size: 12px;" title="Delete process" *ngIf="fileName.status != 'PROCESSING'" type="button" class="btn btn-danger"
          [disabled]="fileName.status =='DELETED' "
            (click)="openModal(confirmModel,fileName.processId, 'modal-sm')">
            <i class="fa fa-trash" aria-hidden="true"></i>
          </button>
          <button style="border-radius:0px;width: 44px;font-size: 12px;" title="Upload file" *ngIf="fileName.status != 'PROCESSING'" type="button" class="btn btn-primary"
          [disabled]="fileName.taskName==='Internal Movement'"
            (click)="openModal(uploadFileModel,fileName.processId, 'modal-sm')">
            <i class="fa fa-upload" aria-hidden="true"></i>
          </button>
           </div>
          </td>
          <td><p class="mb-0">{{fileName.createdBy}}</p>
            <p>{{fileName.createdByOHR}}</p></td>
          <td>
           <div class="d-flex"> MSA:
          <span class="pl-2" tooltip="validMSA">{{fileName.validMSA}}</span>/<span tooltip="totalMSA">{{fileName.totalMSA}}</span></div>
            <div class="d-flex">Population:
              <span class="pl-2" tooltip="validEmployees" >{{fileName.validEmployees}}</span>/<span tooltip="totalEmployees">{{fileName.totalEmployees}}</span></div>
              <div class="d-flex" >Records:
                <span class="pl-2" tooltip="With MSA">{{fileName.total}}</span>/<span tooltip="Without MSA">{{fileName.invalidEmployees}}</span></div>

          </td>
        </tr>
      </tbody>
    </table>
    <!--  -->
  </div><br>
  <div>
    <mat-paginator
      [length]="list.length"
      [pageSize]="pageSize"
      [showFirstLastButtons]="showFirstLastButtons"
      (page)="onPageChange($event)"
    ></mat-paginator>

    <!-- Dropdown for page size -->
    <div style="display: flex; align-items:center;margin-top: -45px;">
      <label style="font-size:12px;" title="pagesize" for="pageSizeSelect"></label>
      <p class="mb-0 mr-2">Items per page: </p>
      <select tooltip="" class="form-select w-100 ml-0 p-2" id="pageSizeSelect" [(ngModel)]="pageSize" style=" width:70px!important;">
        <option value="5">5</option>
        <option value="10">10</option>
        <option value="15">15</option>
        <option value="20">20</option>
        <option value="25">25</option>
        <option value="{{list.length}}">All</option>
        <!-- Add more options as needed -->
      </select>
      <!-- <input type="number" style="margin-left: 1px;"[(ngModel)]="jumpToPage" placeholder="Jump to Page" min="1" [max]="list.length/pageSize" />
      <button (click)="onJumpToPage()">Go</button> -->
  </div>

  <!-- Add this input field to your template -->


  <br>
  <!-- <div class=" my-3 text-center">
    <button type="button" class="btn btn-primary" [routerLink]="['./init']" style="font-family: georgia;">Upload new
      batch </button>
  </div> -->
</div>





<ng-template #confirmModel>
  <div class="modal-body text-center">
    <p>Are you sure you want to delete?</p>
    <button type="button" class="btn btn-default" (click)="deleteBGC()" >Yes</button>
    <button type="button" class="btn btn-primary"  (click)="modalRef?.hide()" >No</button>
  </div>
</ng-template>
<!-- <div class="footer col-12 p-4 mt-1"></div> -->


<ng-template #uploadFileModel>
  <div class="modal-header">
    <h4 class="modal-title pull-left">Upload File</h4>
    <button type="button" class="btn-close close pull-right" aria-label="Close" (click)="modalRef?.hide()">
      <span aria-hidden="true" class="visually-hidden">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <form #myForm="ngForm" (ngSubmit)="uploadFile(myForm)">
      <div class="modal-body">
        <div class="form-group py-3">
          <label for="fileInput">Choose file to upload:</label>
          <input type="file" id="fileInput" name="fileInput" (change)="onFileSelected($event)" required />
        </div>

        <!-- Display uploaded file name -->
        <div *ngIf="uploadedFileName" class="form-group py-3">
          <label>Uploaded File:</label>
          <p>{{ uploadedFileName }}</p>
        </div>

        <button type="submit" class="btn btn-primary mt-4" [disabled]="myForm.invalid">Upload</button>
      </div>
    </form>
  </div>
</ng-template>