import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IDropdownSettings } from 'ng-multiselect-dropdown';
import { BgcService } from 'src/app/shared/service/bgc.service';
import { LoaderService } from 'src/app/shared/service/loader.service';
import { ToastMessageService } from 'src/app/shared/service/toast-message.service';
//import { VoiceRecognitionService } from 'src/app/shared/voice-recognition.service';

@Component({
  selector: 'app-bgc-init',
  templateUrl: './bgc-init.component.html',
  styleUrls: ['./bgc-init.component.scss']
})
export class BgcInitComponent implements OnInit {
  key: string = '';
  inputtask: any;
  inputname: any;
  windowRef = window;
  pendingpid: any;
  isButtonDisabled: boolean = true;




  selectedOption: any = {};
  options = [
    // { id: 1, name: 'Australia' },
    // { id: 2, name: 'Europe' },
    // { id: 3, name: 'China' },
    { id: 4, name: 'India' },
    // { id: 5, name: 'Latin America' },
    // { id: 6, name: 'North America' },
    // { id: 7, name: 'South Africa' },
    // { id: 8, name: 'Phillippines' },
    // { id: 9, name: 'Other'}

    // Add more options as needed
  ];

  testingOption = [
    { id: 'Non-GE', name: 'Non-GE' },
    // { id: 'Digital', name: 'Digital' }
  ];

  allTaskNameOptions = [
    { id: 'New Hire', name: 'New Hire' },
    { id: 'Internal Movement', name: 'Internal Movement' },
    // { id: 'Digital', name: 'Digital' }
  ];

  taskNameOption = [...this.allTaskNameOptions];

  dropdownSettings: IDropdownSettings = {
    singleSelection: true,  // or false if you want multiple selections
    idField: 'id',          // 'id' is the unique identifier in your options array
    textField: 'name',      // 'name' is the field used for display text
    itemsShowLimit: 3,
    maxHeight: 160,
    allowSearchFilter: true
  };


  processId: any;
  bgcDetailData: any;
  count: any = 6;
  baseId!: any;
  stageId!: any;
  constructor(

    private loaderService: LoaderService,
    private toastMessage: ToastMessageService,
    // public speechservice: VoiceRecognitionService,
    private bgcService: BgcService,
    private route: ActivatedRoute,
    private router: Router
  ) {
    this.pendingpid = ''; // Provide appropriate default value
    this.selectedOption = []; // Initialize as an array
    this.inputtask = []; // Initialize as an array
    this.inputname = []; // Initialize as an array
    this.processId = '';
    this.route.params.subscribe((res: any) => {
      if (res?.id) {
        this.processId = res.id;
        this.getBGCDetail();
      }
    })

    this.route.queryParams.subscribe((queryParams: any) => {
      this.baseId = queryParams.baseId;
      this.stageId = queryParams.stageId;

      if (this.stageId) {
        this.getBGCDetail();
      }
    });

    // if(this.inputname == ''){
    //   localStorage.setItem('taskname', 'not new hire')

    // }

  }

  ngOnInit(): void {
    // Fetch the baseId parameter from the URL
    this.baseId = this.route.snapshot.paramMap.get('baseId');
    this.stageId = this.route.snapshot.paramMap.get('stageId') || 1;

    // Log the baseId to verify
    console.log(this.baseId);
    console.log(this.stageId);
  }



  onDropdownChange() {
    // Call this function whenever your dropdown values change
    // Update the button state
    // console.log('this works');
    //  if (this.inputtask[0].name === 'Digital') {
    //   this.taskNameOption = this.allTaskNameOptions.filter(option => option.id === 'Digital');
    // } else {
    //   this.taskNameOption = this.allTaskNameOptions.filter(option => option.id !== 'Digital');
    // }
    this.updateButtonState();
  }

  private updateButtonState() {
    // Adjust the condition based on your requirements
    this.isButtonDisabled = this.pendingpid === 'false' || !this.selectedOption.length || !this.inputtask.length || !this.inputname.length || this.processId;
  }




  bgcintiate() {
    if (this.processId) {
      this.toastMessage.showError('Already process ID generated.')
      return;
    }

    this.loaderService.loading.next(true);
    let token: any = localStorage.getItem('token');
    const payload = {
      "region": this.selectedOption[0].name,
      "taskName": this.inputname[0].name,
      "testingType": this.inputtask[0].name,
      "baseId": this.baseId,
      "stageId": this.stageId,
      //"token": 'Bearer ' + token,
    }
    this.bgcService.bgcinitiate(payload).subscribe((response: any) => {
      this.loaderService.loading.next(false);
      if (response.status == "success" && response?.data) {
        this.toastMessage.showSuccess(response.message);
        this.loaderService.loading.next(true);
        let tempid = response.data;
        this.bgcService.fatchBGC(tempid).subscribe((response: any) => {
          if (response.status == "success") {
            this.loaderService.loading.next(false);
            console.log(response.data.taskName)
            if (response.data.taskName === 'New Hire') {
              this.count == 3
            }
            else {
              this.count == 6;
            }
            this.router.navigate(['bgc/' + tempid, 'upload']);
            // window.location.reload();
          }
          else {
            this.loaderService.loading.next(false);
            this.toastMessage.showError(response.error_message);
          }

        },
          (error) => {
            this.loaderService.loading.next(false);
            this.toastMessage.showError(error.error_message);
          });

      }
      else {
        this.toastMessage.showError(response.error_message);
      }
    }, (error) => {
      this.loaderService.loading.next(false);
    });
  }

  getNextStageId(currentStageId: string): number | null {
    const currentStageNumber = this.convertStringToNumber(currentStageId);
    if (currentStageNumber !== null) {
      return currentStageNumber + 1;
    }
    return null;
  }

  convertStringToNumber(input: string): number | null {
    const result = parseFloat(input);

    // Check if the result is a valid number
    if (isNaN(result)) {
      console.error(`Invalid number: ${input}`);
      return null; // or throw an error based on your use case
    }

    return result;
  }


  getBGCDetail() {

    this.loaderService.loading.next(true);

    this.bgcService.fatchBGC(this.processId).subscribe((response: any) => {
      this.loaderService.loading.next(false);
      if (response.status == "success") {
        this.bgcDetailData = response.data;

        let selectedRegion = this.options.find((ele: any) => ele.name == this.bgcDetailData?.region);
        let selectedTask = this.testingOption.find((ele: any) => ele.name == this.bgcDetailData?.testingType);
        let selectedName = this.taskNameOption.find((ele: any) => ele.name == this.bgcDetailData?.taskName);
        // let stageId = this.bgcDetailData?.stageId;

        this.selectedOption = selectedRegion ? [selectedRegion] : [];
        this.inputtask = selectedTask ? [selectedTask] : [];
        this.inputname = selectedName ? [selectedName] : [];
        // this.stageId = this.getNextStageId(stageId);
        //  console.log(this.inputname[0].name)
        // this.selectedOption = [selectedRegion] || '';
        // this.inputtask = [selectedTask] || '';
        // this.inputname = [selectedName] || '';
        localStorage.setItem('taskname', this.bgcDetailData?.taskName)
      }
      else {
        this.toastMessage.showError(response.error_message);
        //this.router.navigate(['bgc']);
      }
    }, (error: any) => {
      if (error?.error?.error_message) {
        this.router.navigate(['bgc']);
      }
      this.toastMessage.showError(error?.error?.error_message);
      this.loaderService.loading.next(false);
      console.log(error);
    });
  }
}
