import { LoaderService } from '../shared/service/loader.service';
import { Component, ElementRef, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output, SimpleChanges, TemplateRef, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { MeetingNotesService } from '../shared/service/meeting-notes.service';
import { ToastMessageService } from '../shared/service/toast-message.service';
import { BsModalRef, BsModalService } from 'ngx-bootstrap/modal';
import { DocxService } from '../shared/service/docx.service';
import { NgxSpinnerService } from "ngx-spinner";
import { catchError, switchMap, tap } from 'rxjs/operators';
import { of } from 'rxjs';
@Component({
  selector: 'app-meeting-assist',
  templateUrl: './meeting-assist.component.html',
  styleUrls: ['./meeting-assist.component.scss']
})
export class MeetingAssistComponent implements OnInit, OnChanges {
  @ViewChild('scrollChat', { static: false }) scrollContainer!: ElementRef;
  @Output() apiCall = new EventEmitter<any>();
  methodSelected: string =  'GKnowledge';
  @Input() isReset: boolean = false;
  isOpen = false;
  windowRef = window;
  chkagree: boolean = false;
  toggleDropdown() {
    this.isOpen = !this.isOpen;
  }
  username: any;
  userId: string | null = null;
  userPrincipalNamesList: string[] = [];
  dropdownSettings: any = {};
  accountNameSelected: any = [];
  centerSelected: any = [];
  accountList: any = [];
  buyingCenterList: any = ['All','Finance','IT', 'Digital','Analytics',
  'Operations','Supply Chain','Sourcing &Procurement',
'Risk and Compliance','Claims','Life Sciences & Regulatory Affairs',
'Legal','Sales','Marketing','Human Resources','Global Business Services',
'General Management','No Match','Others'
];
accountCheckboxes = ['accnewscheckbox', 'challengescheckbox', 'aboutaccountcheckbox', 'oppurtunitycheckbox', 'competitioncheckbox', 'conversationcheckbox', 'connectioncheckbox', 'acclandscapecheckbox','acctechscapecheckbox','financecheckbox'];
buyingCenterCheckboxes = ['conversationcheckbox', 'connectioncheckbox'];
challengesCheckboxes = ['offeringscheckbox'];
  meetingNotesData: any;
  stopCount = 0;
  intervalId: any;
  chatModeSwitch = true;
  userInput: string = '';
  modalRef?: BsModalRef;
  userAccessStatus: boolean = false;
  likefeedback: string = '';
  unlikefeedback: string = '';
  harmfulcheckbox: boolean = false;
  incorrectcheckbox: boolean = false;
  nothelpfulcheckbox: boolean = false;
  selectallcheckbox: boolean = false;
  sessionId: any;
  table1:  any = {};
  table2:  any = {};
  table3:  any = {};
  table4:  any = {};
  table5:  any = {};
  keys1: string[] = [];
  keys2: string[] = [];
  keys3: string[] = [];
  keys4: string[] = [];
  keys5: string[] = [];
  table1Data: any;
  table2Data: any;
  table3Data: any;
  table4Data: any;
  table5Data: any;
  limit: number = 5;
  about: any;
  reference: any;
  accountNews: any;
  account10Q: any;
  prevConv: any;
  convTable: any;
  dealSummary: any;
  pipeTable: any;
  wonTable: any;
  lostTable: any;
  offerings: any;
  caseStudies: any;
  landscapeTable: any;
  npsScore: any;
  totalClientValue: any;
  ofconTable :any;
  techList: string[] = [];
  acctechTable:any;
  finbenTable :any;
  peertable :any;
  financialbench :any;
  peeranalysis :any;
  isAccountNeeded: boolean = false;
  isBuyingCenterNeeded: boolean = false;
  isChallengeNeeded: boolean = false;
  showCheckboxes: boolean = false;
  showBtn1: boolean = false;
  showBtn2: boolean = false;
  showBtn3: boolean = false;
  showBtn4: boolean = false;
  showBtn5: boolean = false;
  showAll1: boolean = false;
  showAll2: boolean = false;
  showAll3: boolean = false;
  showAll4: boolean = false;
  showAll5: boolean = false;
  noRecordsFound1: boolean = false;
  noRecordsFound2: boolean = false;
  noRecordsFound3: boolean = false;
  noRecordsFound4: boolean = false;
  noRecordsFound5: boolean = false;
  isExpanded = false;
  checkboxes: { [key: string]: boolean } = {
    offeringscheckbox: false,
    accnewscheckbox: false,
    challengescheckbox: false,
    aboutaccountcheckbox: false,
    oppurtunitycheckbox: false,
    competitioncheckbox: false,
    conversationcheckbox: false,
    connectioncheckbox: false,
    acclandscapecheckbox: false,
    acctechscapecheckbox:false,
    financecheckbox:false
  };
  
  constructor(
    private toastMessage: ToastMessageService,
    private meetingNotesService: MeetingNotesService,
    private modalService: BsModalService,
    private docxService: DocxService,
    private loaderService: LoaderService,
    private router : Router,
    private spinner: NgxSpinnerService,
  ) {
    this.dropdownSettings = {
      singleSelection: true,
      allowSearchFilter: true,
      closeDropDownOnSelection: true,
      clearSearchFilter: false,
    };
    // const containerElement = this.scrollContainer.nativeElement;
    //   containerElement.scrollTop = containerElement.scrollHeight;
  }

  ngOnInit(): void {
    this.onLoading();
    this.accessCheck();
    this.getUserName();
    this.geAccountList();
    this.likefeedback = '';
    this.unlikefeedback = '';
    this.harmfulcheckbox = false;
    this.incorrectcheckbox = false;
    this.nothelpfulcheckbox = false;
    this.selectallcheckbox = false;

  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.isReset) {
      this.scrollToBottom();
    }
  }

  accessCheck() {
    // Start loading indicator
    this.loaderService.loading.next(true);
  
    this.meetingNotesService
      .userAccesscheck()
      .pipe(
        tap((res: any) => {
          console.log('User Access Check Response:', res); // Log response
          if (res?.response?.status === 'success') {
            this.userId = res.response.data.user_id; 
            console.log(this.userId);// Store user_id
          } else {
            throw new Error(res?.response?.message || 'User access check failed.');
          }
        }),
        switchMap(() => 
          this.meetingNotesService.getAccessToken().pipe(
            tap((tokenRes: any) => {
              console.log('Access Token Response:', tokenRes.access_token); // Log response
              if (!tokenRes.access_token) {
                throw new Error('Failed to fetch access token.');
              }
            })
          )
        ),
        switchMap((tokenRes: any) => 
          this.meetingNotesService.getUserData(tokenRes.access_token).pipe(
            tap((userRes: any) => {
              console.log('User Data Response:', userRes); // Log response

              // Extract UserPrincipalNames
              const userPrincipalNames = userRes.d.results.map((user: any) => user.UserPrincipalName);
      
              // Save the list of UserPrincipalNames for comparison
              this.userPrincipalNamesList = userPrincipalNames;

              console.log('User Principal Names:', this.userPrincipalNamesList);
              const firstHalf = this.userPrincipalNamesList.slice(0, Math.ceil(this.userPrincipalNamesList.length / 2));
              const matchingItem = firstHalf.find(name => name && name.split('@')[0] === this.userId);
              if (matchingItem) {
                this.userAccessStatus = true;
                console.log('Matching Item:', matchingItem); // Log the matching item
              } else {
                console.log('No match with no access'); // Log no match found
              }
            })
          )
        ),
        catchError((error) => {
          console.error('Access Check Error:', error); // Log error
          this.toastMessage.showError(error.message || 'An error occurred.');
          return of(null); // End the chain on error
        })
      )
      .subscribe({
        next: () => {
          // this.userAccessStatus = true; // Mark access as granted
          this.toastMessage.showSuccess('Access check completed successfully.');
          this.loaderService.loading.next(false);
        },
        error: () => {
          console.error('Unexpected Error during Subscription');
        },
        complete: () => {
          // Always stop the loading indicator
          // this.loaderService.loading.next(false);
        },
      });
  }
  

  toggleDiv() {
    this.isExpanded = !this.isExpanded; // toggle div visibility
  }
  
  // scoroll to bottom in chat window
  scrollToBottom() {
    setTimeout(() => {
      const containerElement = this.scrollContainer.nativeElement;
      containerElement.scrollTop = containerElement.scrollHeight;
    }, 0);
  }

  // copy to clipboard
  // copyToClipboard(text: any) {
  //   const tempInput = document.createElement('textarea');
  //   tempInput.value = text;
  //   document.body.appendChild(tempInput);
  //   tempInput.select();
  //   document.execCommand('copy');
  //   document.body.removeChild(tempInput);
  // }


  //get user Name
  getUserName() {
    this.username = localStorage.getItem('okta-username') 
    ? localStorage.getItem('okta-username') 
    : (localStorage.getItem('teams-username') 
        ? localStorage.getItem('teams-username') 
        : 'Guest');
  }

  geAccountList() {
    // this.meetingLoading = true;
    this.loaderService.loading.next(true);
    this.meetingNotesService.getAccount().subscribe(
      (res: any) => {
        // this.meetingLoading = false;
        if (res?.status == "success") {
          this.accountList = res?.data || [];
          this.sessionId = res?.session_id;
          this.loaderService.loading.next(false);
          // console.log(this.sessionId);
          // this.toastMessage.showSuccess(res?.message);
          // this.openMeetingNotes();
        } else {
          this.toastMessage.showError(res?.message);
        }
      },
      (error : any) => {
        // this.meetingLoading = false;
        this.toastMessage.showError(error?.message);
      }
    )
  }

  // getBuyingCenter() {
  //   // this.meetingLoading = true;
  //   this.centerSelected = [];
  //   this.buyingCenterList = ['All'];
  //   let payload = {
  //     "account": this.accountNameSelected[0]
  //   }
  //   this.meetingNotesService.getBuyingCenters(payload).subscribe(
  //     (res: any) => {
  //       // this.meetingLoading = false;
  //       if (res?.status == "success") {
  //         this.buyingCenterList= res?.data || [];
  //         this.buyingCenterList.unshift('All')
  //         // this.toastMessage.showSuccess(res?.message);
  //         // document.getElementById('prepMeetingtemplate')?.click();
  //       } else {
  //         this.toastMessage.showError(res?.message);
  //       }
  //     },
  //     (error) => {
  //       // this.meetingLoading = false;
  //       this.toastMessage.showError(error?.message);
  //     }
  //   )
  // }

  isAnyCheckboxChecked(checkboxArray: string[]): boolean {
    for (let i = 0; i < checkboxArray.length; i++) {
      let checkbox = checkboxArray[i];
      if (this.checkboxes[checkbox] === true) {
        return true;
      }
    }
    return false;
  }

  handleCheckboxChange() {
    this.isAccountNeeded = this.accountCheckboxes.some((name : any) => this.checkboxes[name]);
    this.isBuyingCenterNeeded = this.buyingCenterCheckboxes.some((name : any) => this.checkboxes[name]);
    this.isChallengeNeeded = this.challengesCheckboxes.some((name : any) => this.checkboxes[name]);
    if(this.isAccountNeeded === true || this.isBuyingCenterNeeded === true || this.isChallengeNeeded === true) {
      this.selectallcheckbox = false;
    }
  }

  show()
  {
    this.showCheckboxes = !this.showCheckboxes;
  }


  checkAll() {
    if(this.selectallcheckbox) 
    {
  this.checkboxes['offeringscheckbox'] = true;
  this.checkboxes['accnewscheckbox'] = true;
  this.checkboxes['challengescheckbox'] = true;
  this.checkboxes['aboutaccountcheckbox'] = true;
  this.checkboxes['oppurtunitycheckbox'] = true;
  this.checkboxes['conversationcheckbox'] = true;
  this.checkboxes['connectioncheckbox'] = true;
  this.checkboxes['competitioncheckbox'] = true;
  this.checkboxes['acclandscapecheckbox'] = true;
  this.checkboxes['acctechscapecheckbox'] = true;
  this.checkboxes['financecheckbox'] = true;  
  this.isAccountNeeded = true;
  this.isBuyingCenterNeeded = true;
  this.isChallengeNeeded = true;
    }
    else if(!this.selectallcheckbox) {
      this.checkboxes['offeringscheckbox'] = false;
      this.checkboxes['accnewscheckbox'] = false;
      this.checkboxes['challengescheckbox'] = false;
      this.checkboxes['aboutaccountcheckbox'] = false;
      this.checkboxes['oppurtunitycheckbox'] = false;
      this.checkboxes['conversationcheckbox'] = false;
      this.checkboxes['connectioncheckbox'] = false;
      this.checkboxes['competitioncheckbox'] = false;
      this.checkboxes['acclandscapecheckbox'] = false;
      this.checkboxes['acctechscapecheckbox'] = false;
      this.checkboxes['financecheckbox'] = false;  
      this.isAccountNeeded = false;
      this.isBuyingCenterNeeded = false;
      this.isChallengeNeeded = false;
    }

  }

  getMeetingNotes() {
    let payload = {
      "account": this.accountNameSelected[0],
      "buying_center": this.centerSelected[0],
      "challenge": this.userInput,
      "session_id": this.sessionId,
      "fields": {
        "offerings": this.checkboxes['offeringscheckbox'].toString(),
        "account_news": this.checkboxes['accnewscheckbox'].toString(),
        "challenges": this.checkboxes['challengescheckbox'].toString(),
        "about_account": this.checkboxes['aboutaccountcheckbox'].toString(),
        "opportunity": this.checkboxes['oppurtunitycheckbox'].toString(),
        "conversation": this.checkboxes['conversationcheckbox'].toString(),
        "connections": this.checkboxes['connectioncheckbox'].toString(),
        "competition": this.checkboxes['competitioncheckbox'].toString(),
        "accounts_landscape": this.checkboxes['acclandscapecheckbox'].toString(),
        "accounts_tech_landscape":this.checkboxes['acctechscapecheckbox'].toString(),
        "finance_benchmark":this.checkboxes['financecheckbox'].toString(),
      }
    }
    this.showCheckboxes = false;
    if(this.centerSelected[0] == 'All') payload.buying_center = 'all';
    // if (!payload.account || !payload.buying_center || !payload.challenge) {
    //   this.toastMessage.showError('Please select some option');
    //   return;
    // }
    // this.meetingLoading = true;
    this.chatModeSwitch = true;
    this.meetingNotesService.getMeetingNotes(payload).subscribe(
      (res: any) => {
        this.modalService.hide();
        // this.loaderService.loading.next(true);
        this.spinner.show();
        // this.meetingLoading = false;
        if (res?.status == "success") {
          // this.loaderService.loading.next(true);
          this.spinner.show();
          this.toastMessage.showSuccess(res?.message);
          // this.meetingLoading = true;
          this.startInterval();
        } else {
          this.toastMessage.showError(res?.message);
        }
      },
      (error : any) => {
        // this.meetingLoading = false;
        this.toastMessage.showError(error?.message);
      }
    )
  }


  // checking status of file per INterver till successed

  startInterval(): void {
    this.intervalId = setInterval(() => {
      this.getMeetingNotesStatus();
    }, 15000); // 15 seconds in milliseconds
  }

  stopInterval() {
    if (this.intervalId) {
      clearInterval(this.intervalId);
      this.intervalId = null;
      // this.meetingLoading = false;
      this.stopCount = 0;
    }
  }

  getMeetingNotesStatus() {
    let payload = {
      "session_id": this.sessionId
    }
    this.meetingNotesService.meetingNotesStatus(payload)
      .subscribe(
        (res: any) => {
          // this.loaderService.loading.next(true);
          this.spinner.show();
          if (res?.status == 'success') {
            this.stopCount++;
            if (res.data !== 0) {
              this.stopInterval();
              // this.loaderService.loading.next(false);
              this.spinner.hide();
              this.chatModeSwitch= false;
              this.meetingNotesData = res?.data;
              this.about = this.meetingNotesData['<h4>About Company </h4>'];
              this.reference = this.meetingNotesData['<h5>References </h5>'];
              this.accountNews = this.meetingNotesData['<h4> Account News from XIQ </h4> '];
              this.account10Q = this.meetingNotesData['<h4> Account Challenges and Priorities per latest 10Q report </h4>'];
              this.prevConv = this.meetingNotesData['<h4>Previous Five Conversations</h4>'];
              this.convTable = this.meetingNotesData['conversation_table'];
              this.dealSummary = this.meetingNotesData['<h4>Deal summary (Pipeline, Won, Lost)</h4>'];
              this.pipeTable = this.meetingNotesData['pipeline_opportunities_table'];
              this.wonTable = this.meetingNotesData['won_opportunities_table'];
              this.lostTable = this.meetingNotesData['lost_opportunities_table'];
              this.offerings = this.meetingNotesData['<h5>Relevant Offerings</h5>'];
              this.caseStudies = this.meetingNotesData['<h5>Relevant case studies</h5> \n'];
              this.npsScore = this.meetingNotesData['nps_score'];
              this.totalClientValue = this.meetingNotesData['client_value_delivered'];
              this.ofconTable = this.meetingNotesData['ofcon_table']
              this.acctechTable = this.meetingNotesData['<h4>Account Technology Landscape <h4>'].account;
              this.techList = this.meetingNotesData['<h4>Account Technology Landscape <h4>'].targeted_tech_presence;
              this.finbenTable = this.meetingNotesData['fiveyearanalysis_table'];
              this.peertable = this.meetingNotesData['competitors_table'];
              // this.financialbench = this.meetingNotesData['<h4> Financial Benchmarking <h4>'];
              // this.peeranalysis = this.meetingNotesData['<h4> Peer Analysis <h4>'];
              

              this.table1 = this.meetingNotesData['<h4>Competitor\u2019s Contracts due for Renewal </h4>'];            
                  if(this.table1 === 'No Records Found')
                {
                  this.noRecordsFound1 = true;
                  this.showBtn1 = false;
                }
                else  if(this.table1.length > 0)
              {
                this.table1Data = this.table1;
                this.keys1 = Object.keys(this.table1Data[0]);
                if(this.table1Data.length < 5)
                { 
                  this.showBtn1 = false;
                }
                else if(this.table1Data.length > 5)
                {
                  this.table1Data = (this.table1).slice(0, 5);
                  this.keys1 = Object.keys(this.table1Data[0]);
                  this.showBtn1 = true;
                } 
              }
              this.table2 = this.meetingNotesData['<h5>Validated connections</h5>'];
              if(this.table2 === 'No Records Found')
                {
                  this.noRecordsFound2 = true;
                  this.showBtn2 = false;
                }
                else  if(this.table2.length > 0)
              {
                this.table2Data = this.table2;
                this.keys2 = Object.keys(this.table2Data[0]);
                if(this.table2Data.length < 5)
                { 
                  this.showBtn2 = false;
                }
                else if(this.table2Data.length > 5)
                {
                  this.table2Data = (this.table2).slice(0, 5);
                  this.keys2 = Object.keys(this.table2[0]);
                  this.showBtn2 = true;
                } 
              }
              this.table3 = this.meetingNotesData['<h5>Yet to be Validated Connections</h5>'];
              if(this.table3 === 'No Records Found')
                {
                  this.noRecordsFound3 = true;
                  this.showBtn3 = false;
                }
                else  if(this.table3.length > 0)
              {
                this.table3Data = this.table3;
                this.keys3 = Object.keys(this.table3Data[0]);
                if(this.table3Data.length < 5)
                { 
                  this.showBtn3 = false;
                }
                else if(this.table3Data.length > 5)
                {
                  this.table3Data = (this.table3).slice(0, 5);
                  this.keys3 = Object.keys(this.table3Data[0]);
                  this.showBtn3 = true;
                } 
              }
              this.table4 = this.meetingNotesData['<h4> Account Operating Landscape </h4> '];            
              if(this.table4 === 'No Records Found')
            {
              this.noRecordsFound4 = true;
              this.showBtn4 = false;
            }
            else  if(this.table4.length > 0)
          {
            this.table4Data = this.table4;
            this.keys4 = Object.keys(this.table4Data[0]);
            if(this.table4Data.length < 5)
            { 
              this.showBtn4 = false;
            }
            else if(this.table4Data.length > 5)
            {
              this.table4Data = (this.table4).slice(0, 5);
              this.keys4 = Object.keys(this.table4Data[0]);
              this.showBtn4 = true;
            } 

          }
        }

            if (this.stopCount > 40) {
              this.toastMessage.showWarning('Please try again later.');
              this.stopInterval();
              this.spinner.hide();
            }

          } else {
            this.toastMessage.showError(res?.message);
            this.stopInterval();
            this.spinner.hide();
          }

        },
        (error) => {
          // Handle the error here
          this.stopInterval();
          console.error(error);
          this.toastMessage.showError(error?.message);
          this.spinner.hide();
          // this.loaderService.loading.next(false);
        }
      );
  }

  feedbackSubmit() {
    let payload = {
      feedback : {
        like : 
        {
          feedback : this.likefeedback,
        },
        unlike : {
          feedback:  this.unlikefeedback,
          harmful: this.harmfulcheckbox,
          incorrect: this.incorrectcheckbox,
          not_helpful: this.nothelpfulcheckbox
        } 
      }
    }
    
    this.meetingNotesService.sendFeedback(payload).subscribe(
      (res: any) => {
        if (res?.status == "success") {
          this.toastMessage.showSuccess(res?.message);
        } else {
          this.toastMessage.showError(res?.message);
        }
      },
      (error : any) => {
        this.toastMessage.showError(error?.message);
      }
    )
    this.likefeedback = '';
    this.unlikefeedback = '';
    this.harmfulcheckbox = false;
    this.incorrectcheckbox = false;
    this.nothelpfulcheckbox = false;
  }


  onItemSelect(event: any) {
    console.log(event)
    // this.getBuyingCenter();
  }

  downloadDocx(): void {
    // Generate and download the .docx file
    this.showMore1();
    this.showMore2();
    this.showMore3();
    setTimeout(() => {
    let html = document.getElementById('download')?.innerHTML;
    this.docxService.generateDocx(html, this.accountNameSelected[0] + '_' + this.centerSelected[0]);
    }, 2000);
  }


  onLoading() {
    const modalbtn = document.getElementById('instructions_modal') as HTMLLIElement;
    modalbtn.click();
  }
  openModal(template: TemplateRef<any>) {
    this.modalRef = this.modalService.show(template, { class: 'modal-md' });
  }

  backToHome()
  {
    this.router.navigate(['/Home']);
  }

  showMore1() {
    this.table1Data = this.table1;
    this.showAll1 = true; 
  }

  showLess1() {
    this.limit = 5;
    this.table1Data = (this.table1).slice(0, this.limit);
    this.showAll1 = false; 
  }

  showMore2() {
    this.table2Data = this.table2;
    this.showAll2 = true; 
  }

  showLess2() {
    this.limit = 5;
    this.table2Data = (this.table2).slice(0, this.limit);
    this.showAll2 = false; 
  }

  showMore3() {
    this.table3Data = (this.table3);
    this.showAll3 = true; 
  }

  showLess3() {
    this.limit = 5;
    this.table3Data = (this.table3).slice(0, this.limit);
    this.showAll3 = false; 
  }

  showMore4() {
    this.table4Data = (this.table4);
    this.showAll4 = true; 
  }

  showLess4() {
    this.limit = 5;
    this.table4Data = (this.table4).slice(0, this.limit);
    this.showAll4 = false; 
  }

  showMore5() {
    this.table5Data = (this.table4);
    this.showAll5 = true; 
  }

  showLess5() {
    this.limit = 5;
    this.table5Data = (this.table5).slice(0, this.limit);
    this.showAll5 = false; 
  }
}
